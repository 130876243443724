// import React from 'react'

// // reactstrap components
// import { Container, Row, Col } from 'reactstrap'

// // Core Components
// import Heart from '../svg/Heart'
// function Header2() {
//   return (
//     <>
//       <header className='header-2 '>
//         <div className='page-header'>
//           <div
//             className='page-header-image'
//             style={{
//               backgroundImage: `url('https://lh3.googleusercontent.com/pw/ACtC-3fktNm2Oby0iVb1NfjUsRRfdaR16aKRDnXv5Qy3yYR9QatYbguVDR2nRjk9rjZ8FH6GiwQD0T0KxaoSklEqoUg1macvJg0cYPSse7s-xSkk4ObA-8fS_5VL9i-GLJTRClKDzKTWpNwZBOyIpU5OAiMZ2Q=w1500-h1000-no?authuser=0')`,
//             }}
//           ></div>
//           <Container className='pt-100'>
//             <Row>
//               <Col className='mx-auto text-center' md='8'>
//                 <h2 className='display-2'>We'll Be back in 2021</h2>
//               </Col>
//             </Row>
//             <Row>
//               <div className='floating-box bg-default'>
//                 <div className='box text-center'>
//                   <div className='icon icon-shape  icon-xl rounded-circle text-white'>
//                     <Heart />
//                   </div>
//                 </div>
//                 <h2 className='lead text-white p-5'>
//                   Earlier this year we made the difficult decision to shut down
//                   operations for the year. We want to thank everyone who
//                   supported us especially our staff and loyal customers. We hope
//                   to see everyone in person in 2021.
//                 </h2>
//               </div>
//             </Row>
//           </Container>
//         </div>
//       </header>
//     </>
//   )
// }

// export default Header2
import React from 'react'

// reactstrap components
import { Container, Row, Col } from 'reactstrap'

// Core Components
import Heart from '../svg/Heart'

function Header4() {
  return (
    <>
      <header className='header-2 skew-separator'>
        <div className='header-wrapper'>
          <div className='page-header ' style={{ padding: '0' }}>
            <div
              className='page-header-image'
              style={{
                backgroundImage: `url('https://lh3.googleusercontent.com/pw/ACtC-3fktNm2Oby0iVb1NfjUsRRfdaR16aKRDnXv5Qy3yYR9QatYbguVDR2nRjk9rjZ8FH6GiwQD0T0KxaoSklEqoUg1macvJg0cYPSse7s-xSkk4ObA-8fS_5VL9i-GLJTRClKDzKTWpNwZBOyIpU5OAiMZ2Q=w1500-h1000-no?authuser=0')`,
              }}
            ></div>
            <Container className='text-center' style={{ padding: '0' }}>
              <Row>
                <Col className='mx-auto' lg='7'>
                  <h1 className='display-1 text-black'>See you in 2021</h1>
                  <Heart />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header4
