import React from 'react'

function Heart(props) {
  const title = props.title || 'save the date'

  return (
    <svg
      height='48'
      width='48'
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <g>
        <path
          d='M45,5H3A2,2,0,0,0,1,7V45a2,2,0,0,0,2,2H45a2,2,0,0,0,2-2V7A2,2,0,0,0,45,5Z'
          fill='#e6e6e6'
        />
        <path
          d='M24,40a1,1,0,0,0,.709-.294l7.563-7.59A5.919,5.919,0,0,0,24,23.649a5.919,5.919,0,0,0-8.271,8.468l7.562,7.59A1,1,0,0,0,24,40Z'
          fill='#e86c60'
        />
        <path
          d='M3,5H45a2,2,0,0,1,2,2v8a0,0,0,0,1,0,0H1a0,0,0,0,1,0,0V7A2,2,0,0,1,3,5Z'
          fill='#e86c60'
        />
        <path
          d='M38,10V2a1,1,0,0,0-1-1H35a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h2A1,1,0,0,0,38,10Z'
          fill='#444'
        />
        <path
          d='M14,10V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h2A1,1,0,0,0,14,10Z'
          fill='#444'
        />
      </g>
    </svg>
  )
}

export default Heart
