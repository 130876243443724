import React, { useState, useEffect } from 'react'
import kwesforms from 'kwesforms'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'

function ContactUs4() {
  const [firstNameFocus, setFirstNameFocus] = useState('')
  const [emailFocus, setEmailFocus] = useState('')
  const [textAreaFocus, settextAreaFocus] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [textArea, setTextArea] = useState('')

  useEffect(() => {
    kwesforms.init()
  }, [])

  //   const handleSubmit = (e) => {
  //     e.preventDefault()

  //     setName('')
  //     setEmail('')
  //     setTextArea('')
  //   }

  return (
    <>
      <div className='contactus-4'>
        <Container>
          <Row>
            <Col className='m-auto' md='12'>
              <Card className='card-contact card-raised'>
                <Row>
                  <Col className='pr-md-0'>
                    <Form
                      className='kwes-form p-3 '
                      id='contact-form-3'
                      method='POST'
                      role='form'
                      action='https://kwes.io/api/foreign/forms/PmYrpi2r761LfKkK7An6'
                    >
                      <CardHeader>
                        <CardTitle tag='h4'>Send us a message</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <FormGroup className={firstNameFocus}>
                              <label>First name</label>
                              <InputGroup>
                                <InputGroupAddon addonType='prepend'>
                                  <InputGroupText>
                                    <i className='ni ni-circle-08'></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  aria-label='First Name...'
                                  placeholder='First Name...'
                                  type='text'
                                  onFocus={() => setFirstNameFocus('focused')}
                                  onBlur={() => setFirstNameFocus('')}
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  name='name'
                                  rules='required'
                                ></Input>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className={emailFocus}>
                          <label>Email address</label>
                          <InputGroup>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                <i className='ni ni-email-83'></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder='Email Here...'
                              type='text'
                              onFocus={() => setEmailFocus('focused')}
                              onBlur={() => setEmailFocus('')}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              name='email'
                              rules='required|email|max:255'
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={textAreaFocus}>
                          <label>Your message</label>
                          <Input
                            id='contact-us-message-4'
                            name='message'
                            rows='6'
                            type='textarea'
                            onFocus={() => settextAreaFocus('focused')}
                            onBlur={() => settextAreaFocus('')}
                            value={textArea}
                            onChange={(e) => setTextArea(e.target.value)}
                            rules='required'
                          ></Input>
                        </FormGroup>
                        <Row>
                          <Col md='6'>
                            <Button
                              className='pull-right'
                              color='info'
                              type='submit'
                              //   onClick={handleSubmit}
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ContactUs4
