import Navigation from './components/navbar/Navigation'
import MainHeader from './components/header/MainHeader'
import ContactPage from 'components/contact/ContactPage'
import Footer from './components/footer/Footer'
import Carousel from './components/imageSection/Carousel'
function App() {
  return (
    <>
      <Navigation />
      <MainHeader />
      <ContactPage />
      <Carousel />
      <Footer />
    </>
  )
}

export default App
