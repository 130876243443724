import React from 'react'

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'

function Blogs2() {
  return (
    <>
      <section className='blogs-2'>
        <Container fluid>
          <Row className='mb-md-5'>
            <Col className='mx-auto' md='8'>
              <h3 className='display-3 text-center'>About Us</h3>
              <p className='lead text-center'>
                La Sonrisa Empanadas (La Sonrisa) was founded in the Spring of
                2010 at the Hester Street Fair in the Lower East Side of
                Manhattan. La Sonrisa grew over time and is now a huge part of
                the ever-growing NYC pop-up market scene and is always in the
                line up of NYC's biggest markets and concerts, such as The
                Governors Ball Music Festival, Electric Zoo Music Festival, the
                Union Square Holiday Market, and Madison Square Eats.
                <p className='lead text-center'>
                  What sets La Sonrisa apart from the competition is our unique
                  and interesting fillings. Our Empanadas are quick, mobile and
                  delicious while offering our customers a chance to enjoy food
                  while taking in the New York City sites.
                </p>
                <p className='lead text-center'>
                  Enjoy our empanadas and don't forget to show us your Sonrisa!
                </p>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg='3'>
              <Card
                className='card-blog card-background'
                data-animation='zooming'
              >
                <div
                  className='full-background'
                  style={{
                    backgroundImage: `url('https://lh3.googleusercontent.com/HmD9-naI1rJo_Jbe00dL24mpJ9dvijt8HJIwTHrR9S1sVfBiyqpri1IlA2HzWzCXMPr8qHWlKLq4wpwhCUh1uivo60oRoY5iln5UC-OSBsA0M5HnInlHBKcPdWmEIWXBXJHwWau4A8j4wW4r53q-UJHfNyv7yp8Y6HtiTDWfPvEIdlmb0hyz3xBzaMXcufk-t_K2BwTw_ZxQZXKlWve6OaljBB-e5P5579_WO6nJErw-9YwFjIbowI1hstsEdaiD10f40EJ7i844ZlpsGQ1eWf6uNuDw3O07eSdB-LSDRMjtVF64pUbPpQ0SzB9k_g0PH4XT8Md7kWnEhCmO8htzF3aMFvTd59ItOVY-059GeIsgJKw1PcNzXVG8izgKTOPcgb-EYugRZb4Khs7rzSbrQlekJJ7Ps3FHK-r3adVRt38BcNfn64IIHNcuIQsJRTfLH0904y3c18BTK3fCdxkUgI5mkf0Ckq4kNbzfPNpBQHEjXWAc_BZD67eU3tvlIVSxrG18mYvWkWIs5ChPkkkMxsdmUkwul4aoL-2V1VhbHz60dQTjwohqIABcaG-HojxOjuVRcP0GE3YJCU-h1tIknf5KXgfRjS_E7YdtivyiSzPVQBHm4M9DU73ktkAvXrKo9vlRugaAn9qSBaHrvHsZcE04rQOP0KiWSOyAlwSLsfXVIdkcO0CBRLHY1m_GE00=s1940-no?authuser=0')`,
                  }}
                ></div>
                <a href='#pablo' onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className='content-bottom'>
                      \<CardTitle tag='h5'>Mushroom Empanadas</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg='3'>
              <Card
                className='card-blog card-background'
                data-animation='zooming'
              >
                <div
                  className='full-background'
                  style={{
                    backgroundImage: `url('https://lh3.googleusercontent.com/v08ydFG0FFka5j30fhqeDZJYGPOapr0cuVsoasbLuAzgkLFJ60LlUWDVk8nqPzl2NC4nXbD6empK1KCQJobwk9gnywYzSoO5COl3AI5NJ5UQlCqAvkLd8HQ6M8k5qvDeoBKU6ZtN6ftajjrIOTFRBjd9HAE-uRNtQF4XfZ4Qfl4EHXarfY6Rw1-rq0us0NToa3b8O56oWqjhqNrDSdPczf7GI0sFqdMILhtIwhMY8FvQL6QAt9okYR7KhKiQ60UR9681Z8-iShDeztwVAJBhS2FHnYlX_S5fNWFeP_igiWqW9rdrDWDSP_wQHho0nguY8MDvcO-ifw7Z4T3Aot3BxfN_f8JZwLPRreNVt1oKXvcuO6Z4U0PXLV6_7jhj8RGob7ALd-NnBKbD73zyzzNb-m5gNVzhQcHZAS68mEzJilq5DO7K0viImWrFQMwMZ6OK-PMHLgM_RaaMGNYqF9eDE8fjD2vUPygEiGOSNLOjT3Vixxy7I6-eRiz7L0AO58DdLgHBHhrDN2ZY-ky2OR-1qizl5GyqnTWP4poJym-KL21_XK0eROitR6RUjCEJild5zzxGvwT3zN8JpBSdNdovEd3t7S562U2lYiwlVONXXcP8GklWR51Dgavs9cOexo16By7Hn-LvCPL5HVDfEq-l5bGdvIedZYXEMJCtH6ffBgDdkhZUuSDKX1lsVgDO39c=w1336-h1000-no?authuser=0')`,
                  }}
                ></div>
                <a href='#pablo' onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className='content-bottom'>
                      \<CardTitle tag='h5'>Mushroom Empanadas</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg='3'>
              <Card
                className='card-blog card-background'
                data-animation='zooming'
              >
                <div
                  className='full-background'
                  style={{
                    backgroundImage: `url('https://lh3.googleusercontent.com/fCGhJmd8V7xUKXPLf-YttTHpvSDCMKiUNcoMYeeHMvwSn36OGpIZJFDgKCcPzwg1KOTlLqzmrRMP3sk9EfpnyTJ5PZni53fQgyGr36Y1tvkpfH8_wo1NFM2zKLHqYKAG0lXLNWtc99khKnhvWywzxLJWpEgOG2uO7i3rj3cCjPPKkvAOHvJ_6b4526n4yw0a21F9k2q6i30Gq-TdoyspkW_ZzJIM_8U1-PWXKedtKgleBFGGQAuLsGF-SDhxxHbzy1shfPbU44Ti5bgBrZjMQL7Q_Raht0KgrQNWrH0TJXnyKRkVukAScOI2RaH_TJgQTQzCVwIy2JHtxmKMRZD2Iazd4BqD7iqV31LgXMrrNDXDJ4Mx7V0atXMXSgKPT9BBjD-KZRg1PIsp9HBLFW93F8J6SZDnsaNIcD7-G3UhP-83YP8a4Zw9BWHhxkHSHBHQ53l5KOOJK23Tn5PN0lOmw-AMbjM3JpE-b7H0IM6ZMpHb2tZEF7YNXaT6DeuwyKfvEcv25kbIq5pOlyrTscsI_PA-nICa_TiFTexmA3jbgAk-bDRoB8bfrjr3bTBt9E3sOYOFvUCjsmxFfga4HdQ4r477wjWySoBBy13p-MwrpggOMEOdtrQzCjdwSWPsrzFhOY1upqUy5au97thc8Ebi4lFds0tn86ENdnI41ry0U9i1yrq-fXwytMn0UjcIwek=w1125-h1117-no?authuser=0')`,
                  }}
                ></div>
                <a href='#pablo' onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className='content-bottom'>
                      <CardTitle tag='h5'>Classic Beef Empanadas</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg='3'>
              <Card
                className='card-blog card-background'
                data-animation='zooming'
              >
                <div
                  className='full-background'
                  style={{
                    backgroundImage: `url('https://lh3.googleusercontent.com/ag0_dn4z4AtmcHq0ehkGplrvn_131rfrnBtfx7ZF4aayUHVcsygRjTgEAVU3FOIgECjuGnuKsz9NDeBc4AAsEs5u1uQjfuiy78gw0-NjtvSxS1aw2TvU6tffKgAaTFy2WJPyqKaaLLGTdw2NMsy_VIamszQENTiBGBKtxAKx4xw9k5h9LhkefUQMcam-eLZ7wDMbGX5ajAwHlfaLxCp8IYGE6LD9HDsjyiXavI4KltnL_8ViqQZR12MM5wp0Asa9wjjv_EA-vWGTCMursFjDvqt7wswnNHGQ19QhFzG5azn67CyPQSe7ZRfi8heR_zphSbRECGuqOMCZT_H4JfWiWy7srcoOOuaykZVwwEQtS74a996RKMlKg91iY6KPtxGPQSMHlcGc1Yfzy9Q4TnYgPZAcFUsV_pURhUseFctpyXY0Z7PHzmn6i9onFmSsjJsTMlyciXKi9IT3eJKvGb2EdgE5aSJgu86CN9QlEiCgbSJqYyNrqCr7_IuUqepyrFDrMytPTMunRhoqa1M7zf_MXZIopXaQ3WEQNwuhhJINIamSOXmM3Iu3izEQWWME4KJBRoOojKgpewKWGvUBav8jrnBHvndk8JlUz0C6yDgMvwEQRLl5fUWzD34kGlFlVwpp4hYAcREOV3L6carqAYETcqRtvFSIzJQHiNp48bg1YLwvjSiat0gCIDZuYaQoZl0=w1125-h1318-no?authuser=0')`,
                  }}
                ></div>
                <a href='#pablo' onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className='content-bottom'>
                      <CardTitle tag='h5'>Coconut Curry Chicken</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Blogs2
