import React from 'react'
import ReactDOM from 'react-dom'

import './assets/css/nucleo-svg.css'
import './assets/css/nucleo-icons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/scss/argon-design-system.scss?v1.0.0'

import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)
