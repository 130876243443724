import React from 'react'

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap'

// Core Components

function Footer3() {
  return (
    <>
      <footer className='footer footer-simple bg-gradient-default'>
        <Container>
          <Row>
            <Col className='text-center'>
              <ul className='social-buttons'>
                <li>
                  <Button
                    className='btn-icon mr-2'
                    color='facebook'
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/LaSonrisaFoods/',
                        '_blank'
                      )
                    }
                    size='sm'
                    target='_blank'
                  >
                    <i className='fab fa-facebook-square'></i>
                  </Button>
                </li>
                <li>
                  <Button
                    className='btn-icon'
                    color='instagram'
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/lasonrisafoods/',
                        '_blank'
                      )
                    }
                    size='sm'
                    target='_blank'
                  >
                    <i className='fab fa-instagram'></i>
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer3
