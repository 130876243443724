import React from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap'

// Core Components

function Navigation() {
  const [collapseOpen, toggleCollapseOpen] = React.useState(false)
  return (
    <>
      <Navbar className='navbar-dark bg-default' expand='lg'>
        <Container>
          <NavbarBrand href='#pablo' onClick={(e) => e.preventDefault()}>
            <img
              style={{ width: '200px', height: '75px' }}
              alt='...'
              src='https://lh3.googleusercontent.com/RQIHiGLpt1mWsYOZ-P72uu92f9hoUj8R889aNencuoe5_a7KQ6hqgRNUXyY3Z1w7up_KFSCSlxXnSUQphL9X1XRkSLCj9lqT3P2-J7--o0xDa369r1jOOl1j9Fo89oCndZNsj2z40-D8BisiWLR2PB5nISwNb3eLiMBOMCpMb56Dl8o8IAT6D8-YInzIrngHC7mjecyhEqgLnb457w-nAOpw_JrTdQX13t1uxCavoGhuakEpo9KwvcYb2xvmVPCpVG9-z6JgbZzeqsPOh-5hoDYI007o_gMTqNBTs6coxVZa0q2yFHC3YFzawvgqVAsSyylVa-7HzrOMrOuwYYl5POnZN8CNGcQv1IvGlpdkViPTN8Jp0c-9QSRGnjz_GR-xJReTFlm3cjHUgqrijvcCiXXzBPKW-0Gy1AKSGb7I9k2JN5dxLYQIhhwPGUCL6_HsmyVvax179akFKcKVqcXo1Q9BJXo6c4Ur9LB9FZX-YRuoN6Sh5WRaIKnqXC3pdaQBJb98jRHTZvtR6qxaun-RlhUI3t-AZM5y6GAOxE_2S9P6A2qArSIUWLZ26HoXJWE271jkNx3pheGyyOoawh7Ckj-PJIXYB8J-9025yPh75x2JeeEJF99J9x9q9ouIJJi9lKpc8qHHXE5jywdLahnOByZY9zv8QWI0Lgn2Q_9qM14reWXhuxcDtAhD5eEallE=w750-h285-no?authuser=0'
            ></img>
          </NavbarBrand>
          <button
            className='navbar-toggler'
            onClick={() => toggleCollapseOpen(!collapseOpen)}
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <Collapse isOpen={collapseOpen} navbar>
            <div className='navbar-collapse-header'>
              <Row>
                <Col className='collapse-brand' xs='6'>
                  <Link to='/index'>
                    <img
                      alt='...'
                      src='https://lh3.googleusercontent.com/RQIHiGLpt1mWsYOZ-P72uu92f9hoUj8R889aNencuoe5_a7KQ6hqgRNUXyY3Z1w7up_KFSCSlxXnSUQphL9X1XRkSLCj9lqT3P2-J7--o0xDa369r1jOOl1j9Fo89oCndZNsj2z40-D8BisiWLR2PB5nISwNb3eLiMBOMCpMb56Dl8o8IAT6D8-YInzIrngHC7mjecyhEqgLnb457w-nAOpw_JrTdQX13t1uxCavoGhuakEpo9KwvcYb2xvmVPCpVG9-z6JgbZzeqsPOh-5hoDYI007o_gMTqNBTs6coxVZa0q2yFHC3YFzawvgqVAsSyylVa-7HzrOMrOuwYYl5POnZN8CNGcQv1IvGlpdkViPTN8Jp0c-9QSRGnjz_GR-xJReTFlm3cjHUgqrijvcCiXXzBPKW-0Gy1AKSGb7I9k2JN5dxLYQIhhwPGUCL6_HsmyVvax179akFKcKVqcXo1Q9BJXo6c4Ur9LB9FZX-YRuoN6Sh5WRaIKnqXC3pdaQBJb98jRHTZvtR6qxaun-RlhUI3t-AZM5y6GAOxE_2S9P6A2qArSIUWLZ26HoXJWE271jkNx3pheGyyOoawh7Ckj-PJIXYB8J-9025yPh75x2JeeEJF99J9x9q9ouIJJi9lKpc8qHHXE5jywdLahnOByZY9zv8QWI0Lgn2Q_9qM14reWXhuxcDtAhD5eEallE=w750-h285-no?authuser=0'
                    ></img>
                  </Link>
                </Col>
                <Col className='collapse-close' xs='6'>
                  <button
                    className='navbar-toggler'
                    onClick={() => toggleCollapseOpen(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className='ml-lg-auto' navbar>
              <NavItem>
                <NavLink
                  className='nav-link-icon'
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/lasonrisafoods/',
                      '_blank'
                    )
                  }
                >
                  <i className='fab fa-instagram'></i>
                  <span className='nav-link-inner--text d-lg-none'>
                    Instagram
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className='text-white'
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/LaSonrisaFoods/',
                      '_blank'
                    )
                  }
                >
                  <i className='fab fa-facebook-square'></i>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navigation
